/** ***************************************************************************
 * Template / Base
 *************************************************************************** */

window.addEventListener('julia:init', () => {
  const toggle = document.querySelector('#nav-toggle');

  function handleToggle() {
    const className = 'nav-open';

    document.querySelector('body').classList.toggle(className, toggle.checked);
  }

  function initialize() {
    handleToggle();
    toggle.addEventListener('change', handleToggle);
  }

  function destroy() {
    toggle.removeEventListener('change', handleToggle);
  }

  window.JULIA.initializers['templates-base'] = initialize;
  window.JULIA.destroyers['templates-base'] = destroy;

  initialize();

});
